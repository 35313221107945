body {
  margin: 0;
  font-size: 1.25rem;
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

button {
  cursor: pointer;
}

button,
input,
select,
option,
optgroup {
  font-family: inherit;
  font-size: inherit;
}
